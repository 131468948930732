import React, { useState, useEffect } from 'react';
import QRCodeComponent from './QRCodeComponent';
import axios from 'axios';
import './PaymentPage.css';

const PaymentPage = () => {
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const [qrcode, setQrcode] = useState(null);
  const [generatedDateTime, setGeneratedDateTime] = useState('');
  const [orderNumber] = useState(() => Math.floor(100000000 + Math.random() * 900000000));
  const [countdownTime, setCountdownTime] = useState(3600);

  // Array de usuários
  const users = [
    { cpf: '66452031287', nome: 'Francisco Gomes De Queiroz', email: 'francisco.gomes@gmail.com', phone: '31959948723' },
    { cpf: '02579303294', nome: 'Karolyne Yngrind Souza De Sena', email: 'karolyne.yngrind@gmail.com', phone: '31959948723' },
    { cpf: '03710547296', nome: 'Kauê Moura Lima Botelho', email: 'moura.botelho@gmail.com', phone: '31959948723' },
    { cpf: '56015316500', nome: 'Osvoaldo De Lima Luci', email: 'osvoaldo.lima@gmail.com', phone: '31959948723' },
    { cpf: '01988218241', nome: 'Iana Maria Feitosa E', email: 'iana.feitosa@gmail.com', phone: '31959948723' },
    { cpf: '04759611290', nome: 'Guilherme Ferraz De Souza Felix', email: 'guilherme.felix@gmail.com', phone: '31959948723' },
  ];


  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdownTime((prev) => (prev > 0 ? prev - 1 : prev));
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, []);

  const getRandomUser = () => users[Math.floor(Math.random() * users.length)];

  useEffect(() => {
    const fetchData = async () => {
      // Seleciona um usuário aleatoriamente
      const userData = getRandomUser();
      setUserDetails(userData);

      try {
        const secretKey = process.env.REACT_APP_SECRET_KEY;
        const base64Value = btoa(`${secretKey}:x`);
        console.log('chave em base64:' + base64Value);

    const options = {
      method: 'POST',
      url: 'https://cors-anywhere.herokuapp.com/https://api.zyonpay.net/functions/v1/transactions',
      headers: {
        authorization: 'Basic ' + base64Value,
      },
      data: {
        customer: {
          phone: userData.phone,
          email: userData.email,
          name: userData.nome,
          document: { number: userData.cpf, type: 'CPF' }
        },
        amount: 4474,
        items: [
          {
            title: 'TAXA',
            unitPrice: 4474,
            quantity: 1,
            externalRef: 'TAXA'
          }
        ],
        paymentMethod: 'PIX'
      }
    };

      const response = await axios(options);
      setQrcode(response.data.pix.qrcode);
      setLoading(false);
      setGeneratedDateTime(getCurrentDateTime());
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const copyPixCode = () => {
    if (qrcode) {
      navigator.clipboard.writeText(qrcode).then(() => {
        alert('Código Pix copiado para a área de transferência!');
      }, (err) => {
        console.error('Erro ao copiar o texto: ', err);
      });
    }
  };

  const getCurrentDateTime = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year}, ${hours}:${minutes}`;
  };

  const countdownDisplay = () => {
    const minutes = Math.floor(countdownTime / 60);
    const seconds = countdownTime % 60;
    return `${String(minutes).padStart(2, '0')}m ${String(seconds).padStart(2, '0')}s`;
  };

  return (
    <div>
      {loading ? (
        <div className="loadingContainer">
          <div className="loadingDialog">
            <p>Aguarde enquanto geramos o QR Code</p>
            <div className="loadingSpinner"></div>
          </div>
        </div>
      ) : userDetails ? (
        <div className="documentContainer">
          <div className="logo">
            <img 
              src="./qrcode.svg"
              alt=""
              width={250}
              height={250}
              className="svgLogo" 
            />
          </div>
          <h2 className="title">Seu pagamento foi gerado com sucesso!</h2>
          <p className="subtitle">
            Realize o pagamento com o QR Code abaixo ou clique no botão para copiar e colar o link de pagamento
          </p>
          <div className="orderInfo">
            <span className="orderInfoText">
              PEDIDO: <strong className="pedido">#{orderNumber}</strong>
            </span>
            <span className="orderInfoText">
              STATUS: AGUARDANDO PAGAMENTO DO QRCODE
            </span>
          </div>
          <p className="geradoEm">Gerado em: {generatedDateTime}</p>

          <div className="qrcodeSection">
            {qrcode && <QRCodeComponent qrcodeData={qrcode} />}
            <div className="qrcodeInstructions">
              <p>1. Abra o app do seu banco ou instituição financeira e entre no ambiente Pix.</p>
              <p>2. Escolha a opção Pagar com QR Code e escaneie o código ao lado.</p>
              <p>3. Confirme as informações e finalize o pagamento.</p>
              <p className="expiration">
                Expira em: <span className="countdown">{countdownDisplay()}</span>
              </p>
            </div>
          </div>

          <div className="paymentInfo">
            <p className="paymentTextInfo">Pague com PIX em qualquer dia e qualquer hora!</p>
            <p className="paymentTextInfo">O pagamento é seguro, instantâneo, prático e pode ser feito em poucos segundos.</p>
            <button className="copyButton" onClick={copyPixCode}>
              Pagar com PIX copia e cola
            </button>
          </div>

          <p className="footerNote">
            Após o pagamento, podemos levar alguns segundos para a confirmação.
            <br />
            Não se preocupe, você será avisado assim que confirmado!
          </p>
        </div>
      ) : (
        <div className="documentContainer">
          <p>Nenhum dado encontrado.</p>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
