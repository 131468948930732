import React, { useRef, useEffect } from 'react';
import QRCode from 'qrcode';

const QRCodeComponent = ({ qrcodeData }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const generateQRCode = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      let width = 200; // Largura padrão para desktop

      // Verifique se a tela é um dispositivo móvel
      if (window.matchMedia('(max-width: 768px)').matches) {
        width = window.innerWidth * 0.5; // 50% da largura da tela em dispositivos móveis
      }

      // Defina o tamanho do canvas
      canvas.width = width;
      canvas.height = width; // Manter uma proporção quadrada

      QRCode.toCanvas(canvas, qrcodeData, { width: width }, function (error) {
        if (error) console.error(error);
        console.log('QR Code generated!');
      });
    };

    generateQRCode();
  }, [qrcodeData]); // Executa apenas quando qrcodeData muda

  return (
    <div>
      <canvas ref={canvasRef} style={{ maxWidth: '100%', height: 'auto' }} />
    </div>
  );
};

export default QRCodeComponent;
